<template>
  <v-container fluid>
    <v-row>
      <v-col
        ref="exp_stepper"
        cols="12"
        class="exp-stepper"
      >
        <v-stepper
          v-if="pages.length > 0"
          :value="currentPage"
          @change="updateStepFromStepper"
        >
          <v-stepper-header>
            <template v-for="page in pages">
              <v-stepper-step
                :key="`${page.stepNum}-step`"
                :step="page.stepNum"
                :complete="currentPage > page.stepNum"
                :editable="page.stepNum <= maxEditableStep"
                @click.native.capture="page.stepNum <= maxEditableStep ? skipToPage(page.stepNum) : ''"
              >
                {{ page.title }}
              </v-stepper-step>
              <v-divider
                v-if="page.stepNum !== pages.length"
                :key="`${page.stepNum}-div`"
              />
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              v-for="page in pages"
              :key="`${page.stepNum}-content`"
              :step="page.stepNum"
            >
              <v-row>
                <v-col cols="12">
                  <v-toolbar
                    class="elevation-0"
                    color="secondary"
                  >
                    <v-toolbar-title>{{ page.desc }}</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-btn
                        v-if="page.load"
                        :disabled="page.load.disabled"
                        class="font-weight-bold"
                        color="primary"
                        text
                        @click="openPreloadDialog"
                      >
                        <v-icon left>
                          mdi-{{ page.load.icon }}
                        </v-icon>
                        {{ page.load.title }}
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-divider />
                </v-col>
                <v-col cols="12">
                  <v-form
                    :ref="`step${page.stepNum}form`"
                    v-model="formValid"
                    lazy-validation
                  >
                    <FormField
                      v-for="field in page.fields"
                      :key="field.key"
                      :entry="field"
                      @onDynamicUpdate="refreshForm"
                    />
                  </v-form>
                </v-col>
                <v-col
                  v-if="page.groups"
                  cols="12"
                >
                  <v-stepper
                    v-if="page.groups.length > 0"
                    ref="group_stepper"
                    v-model="currentGroup"
                    vertical
                  >
                    <template v-for="group in page.groups">
                      <v-stepper-step
                        :key="'titleKey_'+group.stepNum"
                        editable
                        :complete="currentGroup > group.stepNum"
                        :step="group.stepNum"
                      >
                        {{ group.title }}
                        <small>{{ group.desc }}</small>
                      </v-stepper-step>

                      <v-stepper-content
                        :key="'contentKey_'+group.stepNum"
                        :step="group.stepNum"
                        class="group-step"
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-form
                              :ref="`group${group.stepNum}form`"
                              v-model="groupFormValid"
                              lazy-validation
                            >
                              <FormField
                                v-for="groupField in group.fields"
                                :key="groupField.key"
                                :entry="groupField"
                                :disabled="loading"
                                @onDynamicUpdate="refreshForm"
                              />
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </template>
                  </v-stepper>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    rounded
                    color="primary"
                    :disabled="loading"
                    @click.stop="nextStep"
                  >
                    <span v-if="page.stepNum < pages.length">
                      'Continue'
                    </span>
                    <span v-else>
                      'Save'
                    </span>
                  </v-btn>
                  <v-btn
                    v-if="page.stepNum > 1"
                    text
                    rounded
                    :disabled="loading"
                    @click.stop="backStep"
                  >
                    Go Back
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>

    <v-dialog
      v-model="preloadDialogOpen"
      max-width="500px"
      persistent
    >
      <v-card
        tile
        class="popup-dialog"
      >
        <v-toolbar
          card
          dark
          color="primary"
        >
          <v-toolbar-title>Preload Dialog Panel</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <FormField
            :entry="preloadEntryData"
            :pull-focus="preloadDialogOpen"
            @onEnter="requestPreload"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            rounded
            @click="requestPreload"
          >
            Preload
          </v-btn>
          <v-btn
            text
            rounded
            @click="closePreloadDialog"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from '../scripts/event_bus'

export default {
  components: {
    FormField: () => import('./FormField')
  },
  data: () => ({
    loading: false,
    formValid: false,
    groupFormValid: false,
    pages: [],
    currentPage: 1,
    maxEditableStep: 1,
    currentGroup: 1,
    firstLoad: true,
    preloadDialogOpen: false,
    preloadEntryData: {
      key: 'template_id',
      type: 'shorttext',
      dynamic: false,
      value: null,
      title: 'Experiment ID',
      desc: 'Enter the experiment id to copy',
      required: true,
      error: null
    }
  }),
  watch: {
    $route: function () {
      if (this.$route.name === 'run_exp') {
        // console.log('Reset run exp form from route change')
        this.reset()
      }
    }
  },
  mounted () {
    this.reset()
    // Subscribe to the global refresh event, but only refresh when open
    EventBus.$on('refresh', this.refreshForm)
    // Just make sure the value is cleared
    this.preloadEntryData.value = null
  },
  beforeDestroy () {
    EventBus.$off('refresh', this.refreshForm)
  },
  methods: {
    openPreloadDialog: function () {
      // Clear any errors
      this.preloadEntryData.error = null
      // Open the dialog
      this.preloadDialogOpen = true
      // Get the prefill experiment text field from the refs
      // let expIdField = this.$refs['preloadForm'].$refs[this.preloadEntryData.key]
      // // Queue the focus pull for the next update
      // this.$nextTick(expIdField.focus)
    },
    closePreloadDialog: function () {
      this.preloadDialogOpen = false
    },
    requestPreload: function () {
      if (this.preloadEntryData.value) {
        console.log('Sending request to prefill from expID:', this.preloadEntryData.value)

        const dataToSend = []

        dataToSend.push({ key: this.preloadEntryData.key, value: this.preloadEntryData.value })
        dataToSend.push({ key: 'experiment_id', value: this.$route.params.exp_id })
        dataToSend.push({ key: 'step', value: this.currentPage })
        dataToSend.push({ key: 'action', value: 'load' })

        // Set the loading boolean true
        this.loading = true
        // And then initiate the data transfer
        this.$root.$data.si.postLEDExpForm(dataToSend, respData => {
          // Check to see if the server responded with an error or not
          if (respData.action !== 'error') {
            // It did not, cool
            console.log('Experiment exists, well done')

            // Now is where we merge the new prefill data into the local data.
            // This is done by looping over the local data, and whenever the prefill data
            // has a matching field, we load that value in place of the local value.
            // If the local data has less fields than the prefill data, the extra prefill
            // data will be discarded, and where the prefill data has less than the local
            // data, the local data will only be prefilled to that point.

            // Group type checking
            const localGroupTypes = []
            const prefillGroupTypes = []

            // The regex check to find the group type
            const fieldsNumTest = /^group_\d{1,}_type$/

            // Get the number of groups of the local data and of the prefill data
            const groupsNumLocal = this.pages[0].fields.find(field => {
              return field.key === 'groups_number'
            }).value
            const groupsNumPrefill = respData.pages[0].fields.find(field => {
              return field.key === 'groups_number'
            }).value

            // Fill the array of group types for the local data
            if (groupsNumLocal < 2) {
              localGroupTypes.push(
                this.pages[0].fields.find(field => {
                  return fieldsNumTest.test(field.key)
                }).value
              )
            } else {
              this.pages[0].groups.forEach(group => {
                localGroupTypes.push(
                  group.fields.find(field => {
                    return fieldsNumTest.test(field.key)
                  }).value
                )
              })
            }

            // Fill the array of group types for the prefill data
            if (groupsNumPrefill < 2) {
              prefillGroupTypes.push(
                respData.pages[0].fields.find(field => {
                  return fieldsNumTest.test(field.key)
                }).value
              )
            } else {
              respData.pages[0].groups.forEach(group => {
                prefillGroupTypes.push(
                  group.fields.find(field => {
                    return fieldsNumTest.test(field.key)
                  }).value
                )
              })
            }

            // Conversion from human readable to machine readable
            const pageIndex = this.currentPage - 1
            // Pull the relevant new data to prefill from the data given by the server
            const newGroups = respData.pages[pageIndex].groups
            // Do we need to actually to a check to make sure the experiments match to merge?
            const allowDiffMerge = true // respData.pages[pageIndex].load.allow

            // Epic for loop of doom, start by looping over the groups
            this.pages[pageIndex].groups.forEach((group, gIndex) => {
              // Check to make sure that the group in the local data, exists in the new prefill data
              if (newGroups[gIndex]) {
                if (localGroupTypes[gIndex] === prefillGroupTypes[gIndex] || allowDiffMerge) {
                  console.log('Loading group', gIndex)
                  // Now loop over the fields in the group
                  group.fields.forEach((field, fIndex) => {
                    // Check to make sure that the field in the local data, exists in the new prefill data
                    if (newGroups[gIndex].fields[fIndex]) {
                      // Shorten that call by pulling out that new prefill field data
                      const newField = newGroups[gIndex].fields[fIndex]
                      // We only want to update data that have keys, as they are actually important...
                      if (newField.key) {
                        // Update the local field value with the new prefill value from the server
                        field.value = newField.value

                        console.log('Loading field', fIndex, 'of group', gIndex)
                      } else {
                        console.log('Field', fIndex, 'has no key, not loading value for it')
                      }
                    } else {
                      console.log('Field', fIndex, 'of group', gIndex, 'does not exist in new data')
                    }
                  })
                } else {
                  EventBus.$emit('info', 'Cannot prefill into different experiment types.')
                  console.log('Cannot prefill into different experiment types.')
                }
              } else {
                console.log('Group', gIndex, 'does not exist in new data')
              }
            })
            // We are done loading the prefill data in, so close the dialog
            this.preloadDialogOpen = false
          } else {
            console.log('Experiment does not exist, I think, bad job!')
            this.preloadEntryData.error = respData.error_message
          }
          // Processing of the response is done, so we are not loading anymore
          this.loading = false
        })
      } else {
        // Don't let the user try to load using a number as the entry...
        this.preloadEntryData.error = 'You must enter a number value'
      }
    },
    reset: function () {
      this.currentPage = 1
      this.currentGroup = 1
      this.pages = []
      this.firstLoad = true
      this.refreshForm()
    },
    refreshForm: function () {
      if (this.$route.name === 'run_exp') {
        this.loading = true

        this.$root.$data.si.postLEDExpForm(this.getPagePostData('refresh'), respData => {
          // console.log(respData)

          if (respData.pages) {
            this.pages = respData.pages
          } else {
            console.log('Oh dear... There was no page data!!')
          }

          if (respData.start_page) {
            if (respData.start_page <= this.pages.length) {
              if (this.firstLoad) this.currentPage = respData.start_page

              // console.log('Starting page of', respData.start_page, 'was requested for first load.')
            } else {
              if (this.firstLoad) this.currentPage = this.pages.length

              // console.log('Page requested', respData.start_page, 'is more than there are pages', this.pages.length)
            }

            this.maxEditableStep = respData.start_page
            this.firstLoad = false
          }

          if (respData.error_message != null) {
            EventBus.$emit('error', {
              title: 'Error in step',
              message: respData.error_message
            })
          }

          this.loading = false
        })
      }
    },
    nextStep () {
      // If there is an inner group stepper, and it is not at the end,
      // then the continue button moves that forward instead of the outer stepper
      const currentGroupData = this.pages[this.currentPage - 1].groups
      if (currentGroupData ? this.currentGroup < currentGroupData.length : false) {
        this.currentGroup++
        return
      }

      if (!this.loading) {
        this.loading = true

        this.$root.$data.si.postLEDExpForm(this.getPagePostData('save'), respData => {
          console.log(respData)

          this.pages = respData.pages

          if (respData.action === 'saved') {
            if (this.$route.params.exp_id === 'new') {
              this.$router.push({ name: 'run_exp', params: { exp_id: respData.experiment_id } })
              console.log('New experiment, assigning an ID of ' + respData.experiment_id + '.')
            } else if (this.$route.params.exp_id !== respData.experiment_id) {
              console.log('Very very bad error, experiment ID does not match between the URL (',
                this.$route.params.exp_id, ') and the server response (', respData.experiment_id, ').')
            } else {
              console.log('Existing experiment with ID of ' + respData.experiment_id + '.')
            }

            if (this.currentPage < this.pages.length) {
              this.currentPage++
              this.currentGroup = 1
            }
          } else if (respData.error_message != null) {
            EventBus.$emit('error', {
              title: 'Error in step',
              message: respData.error_message
            })
          }

          this.loading = false
        })
      }
    },
    getPagePostData: function (action) {
      const dataToSend = []

      if (this.pages.length > 0) {
        const currentPageData = this.pages[this.currentPage - 1]
        // Add the fields, if they exist
        if (currentPageData.fields) {
          currentPageData.fields.forEach(field => {
            dataToSend.push(field)
          })
        }
        // Add the group fields, if they exist
        if (currentPageData.groups) {
          currentPageData.groups.forEach(group => {
            group.fields.forEach(field => {
              dataToSend.push(field)
            })
          })
        }
      }

      dataToSend.push({ key: 'experiment_id', value: this.$route.params.exp_id })
      dataToSend.push({ key: 'step', value: this.currentPage })
      dataToSend.push({ key: 'action', value: action })

      return dataToSend
    },
    backStep: function () {
      if (this.currentGroup > 1) {
        this.currentGroup--
      } else if (this.currentPage > 1) {
        this.refreshForm()
        this.currentPage--
        if (this.pages[this.currentPage - 1].groups) {
          // Not all pages have sub-groups
          this.currentGroup = this.pages[this.currentPage - 1].groups.length
        }
        // console.log('Going back to group', this.currentGroup)
      }
    },
    updateStepFromStepper: function (newStep) {
      // console.log('Moved to group', newStep)

      this.currentPage = newStep
      this.refreshForm()
    },
    skipToPage: function (newStep) {
      this.currentPage = newStep
      this.refreshForm()

      // console.log('Skipping to page', newStep, '-> BTW, ignore the *this.getSteps is not a function* error ;)')
    }
  }
}
</script>

<style scoped>

</style>
